<template>
  <div class="full_popup p-0 d-none d-md-block">
    <DesktopWizardTopBar :wizardSteps="wizardSteps" :page="page" />
    <TheCurve>
      <div class="row mx-0 justify-content-center">
        <StepCount :page="page" :totalPages="totalPages" />
        <ThePage>
          <slot/>
        </ThePage>
        <Footer @goBack="back" @goNext="next" @save="save" :page="page" :totalPages="totalPages" :saveDisabled="saveDisabled" :isLoading="isLoading" :nextDisabled="nextDisabled" />
      </div>
    </TheCurve>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    DesktopWizardTopBar: defineAsyncComponent(() => import('./DesktopWizardTopBar.vue')),
    StepCount: defineAsyncComponent(() => import('./StepCount.vue')),
    Footer: defineAsyncComponent(() => import('../views/footer/Footer.vue')),
    ThePage: defineAsyncComponent(() => import('./ThePage.vue')),
    TheCurve: defineAsyncComponent(() => import('./TheCurve.vue'))
  },
  props: ['page', 'totalPages', 'nextDisabled', 'saveDisabled', 'isLoading', 'wizardSteps'],
  emits: ['goBack', 'goNext', 'save'],
  methods: {
    back () {
      this.$emit('goBack')
    },
    next () {
      this.$emit('goNext')
    },
    save () {
      this.$emit('save')
    }
  }
}
</script>
<style>
  .full_popup {
    position: fixed;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
</style>
